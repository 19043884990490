<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">日程列表</p>
            <p class="btn-common" @click="editSession(null)">新增日程</p>
        </div>
        <div class="table-margin-top flex-1-overflow-y">
            <el-tabs v-model="activeName">
                <el-tab-pane v-for="(item, parentIndex) in dataList" :key="parentIndex" :label="item.content_data.name"
                    :name="'' + (parentIndex + 1)">
                    <div class="table-header table-margin-bottom">
                        <div class="table-index">序号</div>
                        <div class="table-name" style="line-height: unset;">名称</div>
                        <div class="table-type" style="font-weight: normal">类型</div>
                        <div class="table-date">日期</div>
                        <div class="table-start_time">开始时间</div>
                        <div class="table-end_date">结束时间</div>
                        <div class="table-total_times">总时长</div>
                        <div class="table-zanzhu" style="font-weight: normal;line-height: unset"> 赞助商</div>
                        <div class="table-room" style="font-weight: normal;line-height: unset">会议房间</div>
                        <div class="table-room-time" style="font-weight: normal;line-height: unset">演讲时间</div>
                        <div class="table-operation">操作</div>
                    </div>
                    <div v-for="(session, index) in item.content_children" :key="index"
                        class="table-item table-margin-bottom">
                        <div class="table-index">{{ (index + 1) }}</div>
                        <div class="table-name">{{ session.name }} </div>
                        <div class="table-type">{{ session.type }}</div>
                        <div class="table-date">{{ formatShowDate(session.date) }}</div>
                        <div class="table-start_time">{{ session.show_start_time }}</div>
                        <div class="table-end_date">{{ session.show_end_time }}</div>
                        <div class="table-total_times">{{ session.duration }}分钟</div>
                        <div class="table-zanzhu">{{ session.partner ? session.partner.name :
                            '' }}</div>
                        <div class="table-room">{{ session.meetingRoom ?
                            session.meetingRoom.name : '' }}</div>
                        <div class="table-room-time">{{ session.speechTime }}</div>

                        <div class="table-operation">
                            <el-dropdown>
                                <span class="el-dropdown-link" style="cursor: pointer;">
                                    ...
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <div @click="gotoGuests(session)">嘉宾列表</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="toPPT(session)">文件列表</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="editSession(session)">编辑</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="doRemoveSession(session)">删除</div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <!-- <div style="display: flex;">

                        <p class="list-btn-blue" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                            @click="editSession(session)">编辑</p>
                    </div>
                    <div style="display: flex;margin-top: 10px;">
                        <p class="list-btn-blue" @click="toPPT(session)">文件列表</p>
                        <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                            @click="doRemoveSession(session)">删除</p>
                    </div> -->
                        </div>
                    </div>


                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog :visible.sync="showSessionDialog" :title="sessionInfo.id == '' ? '添加日程' : '编辑日程'">
            <div>
                <el-form :model="sessionInfo" :rules="rules" ref="sessionForm">
                    <el-form-item label="日程名称" prop="name">
                        <el-input v-model="sessionInfo.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="议程类型">
                        <el-select v-model="sessionInfo.type" :clearable="true" placeholder="请选择">
                            <el-option label="嘉宾致辞" value="嘉宾致辞"></el-option>
                            <el-option label="主题演讲" value="主题演讲"></el-option>
                            <el-option label="圆桌论坛" value="圆桌论坛"></el-option>
                            <el-option label="休息" value="休息"></el-option>
                        </el-select>
                        <el-input v-model="custom_type" placeholder="自定义议程类型"
                            style="width: 300px;margin-left: 15px;"></el-input> (自定义)
                    </el-form-item>
                    <el-form-item label="是否开放">
                        <el-select v-model="sessionInfo.check_ticket" placeholder="请选择">
                            <el-option label="是" value="NO" />
                            <el-option label="否" value="YES" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日程日期" prop="date">
                        <el-select v-model="sessionInfo.date" placeholder="请选择">
                            <el-option v-for="item in dateRange" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="start_time_date">
                        <el-time-picker v-model="sessionInfo.start_time_date" format="HH:mm" placeholder="选择时间">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="日程时长" prop="duration">
                        <el-input-number v-model="sessionInfo.duration" placeholder="请输入"></el-input-number>（单位分钟）
                    </el-form-item>
                    <el-form-item label="所属日程组">
                        <el-select v-model="sessionInfo.session_group_id" placeholder="请选择">
                            <el-option v-for="(group, index) in sessionGroups" :key="index" :label="group.name"
                                :value="group._id.$id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="品牌客户">
                        <el-select v-model="sessionInfo.partner_id" :clearable="true" placeholder="请选择">
                            <el-option v-for="(item, index) in partners" :key="index" :label="item.partner.name"
                                :value="item.partner._id.$id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="会议房间">
                        <el-select v-model="sessionInfo.meeting_room_id" :clearable="true" placeholder="请选择">
                            <el-option v-for="(meetingRoom, index) in meetingRooms" :key="index"
                                :label="meetingRoom.name" :value="meetingRoom._id.$id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="演讲时间">
                        <el-input-number v-model="sessionInfo.speechTime" placeholder="请输入"></el-input-number>（单位分钟）
                    </el-form-item>

                    <el-form-item label="">
                        <label>简介</label>
                        <quill-editor class="editor" style="margin-bottom:20px" ref="myTextEditor"
                            v-model="sessionInfo.intro" :options="editorOption">
                        </quill-editor>
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showSessionDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveSession()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getAllDatesInRange } from '@/utils/date'
import dayjs from 'dayjs'
import {
    getMeetingInfo,
    getMeetingPartners,
    getMeetingRooms,
    getPartners,
    getSessionGroups,
    getSessions,
    removeSession,
    updateSession
} from '../../api/api'
import '../../assets/common/common.css'
import {
    formatDate
} from '../../utils/date'

//TODO: 然后日程的时间，不要自动获取现在的时间

export default ({
    name: 'index',
    data() {
        return {
            activeName: '1',

            meeting_id: this.$route.query.meeting_id,
            dateRange: [], // 开始日期范围
            dataList: [],
            custom_type: '',
            dafaultValue: undefined,
            sessionInfo: {
                id: '',
                meeting_id: this.meeting_id,
                name: '',
                date: '',
                start_time_date: new Date(), // 用于显示时间，不需要传到后端
                start_time: '',
                duration: '',
                intro: '',
                session_group_id: '',
                type: '',
                partner_id: '',
                meeting_room_id: '',
                speechTime: '',
                check_ticket: 'NO'
            },
            rules: {
                name: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                date: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                start_time: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                duration: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ]
            },
            showSessionDialog: false,
            sessionGroups: [],
            meetingInfo: {},
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ] // 工具菜单栏配置
                },
                placeholder: '', // 提示
                readyOnly: false, // 是否只读
                theme: 'snow', // 主题 snow/bubble
                syntax: true // 语法检测
            },
            partners: [],
            meetingRooms: []
        }
    },
    mounted() {
        this.dafaultValue = new Date()
        this.dafaultValue.setHours(9)
        this.dafaultValue.setMinutes(0)
        // this.dafaultValue.setSeconds(0)
        this.fetchData()
        this.requestSessionGroups()
        this.requestMeetingInfo()
        this.requestPartners()
        this.requestMeetingRooms()
    },
    methods: {
        handleCommand(command) {

            if (command == 'a') {

            }
            if (command == 'b') {

            }
            if (command == 'c') {

            }
            if (command == 'd') {

            }

        },

        requestMeetingRooms() {
            getMeetingRooms(this.meeting_id).then((res) => {
                this.meetingRooms = res.data.data
            })
        },

        async requestPartners() {
            let partnerList = (await getMeetingPartners(this.meeting_id)).data.data || []
            this.partners = partnerList
            // let dataList = (await getPartners(this.meeting_id)).data.data || []
            // partnerList.map(item => {
            //     dataList.map(it => {
            //         if (item.meeting_id === it.meeting_id) {
            //             this.partners.push(item.partner)
            //         }
            //     })
            // })
        },

        // requestSponsorContents() {
        //     getSponsorContents(this.meeting_id).then((res) => {
        //         let result = res.data.data
        //         this.sponsorContents = result.filter((item) => item.type == '演讲赞助')
        //     })
        // },

        formatTime(time) {
            return parseInt(time.substring(0, 1)) * 10 + parseInt(time.substring(1, 2))
        },
        formatTimeToString(time) {
            if (time >= 10) {
                return time
            }
            return '0' + time
        },
        requestMeetingInfo() {
            getMeetingInfo(this.meeting_id).then((res) => {
                const { start_date, end_date } = res.data.data
                this.meetingInfo = res.data.data
                this.dateRange = getAllDatesInRange(start_date * 1000, end_date * 1000)
            })
        },

        gotoGuests(row) {
            this.$router.push({
                path: '/meeting/session/guests',
                query: {
                    session_id: row._id.$id,
                    meeting_id: this.meeting_id
                }
            })
        },
        checkGroupItem(array, group_id) {
            for (let i = 0; i < array.length; i++) {
                let content = array[i]
                if (content.content_type == 'session_group') {
                    if (content.content_data._id.$id == group_id) {
                        return i
                    }
                }
            }
            return -1
        },

        fetchData() {
            getSessions(this.meeting_id).then((res) => {
                let result = res.data.data
                let tempArray = []
                result.map((item) => {
                    if (item.session_group_id && item.session_group_id != null) {
                        let checkResult = this.checkGroupItem(tempArray, item.session_group_id)
                        if (checkResult != -1) {
                            tempArray[checkResult].content_children.push(item)
                        } else {
                            let content = {}
                            content['content_type'] = 'session_group'
                            content['content_data'] = item.group
                            content['content_children'] = [];
                            content['content_children'].push(item)
                            tempArray.push(content)
                        }
                    } else {
                        let content = {}
                        content['content_type'] = 'session';
                        content['content_data'] = item;
                        content['content_children'] = [];
                        content['content_children'].push(item)
                        tempArray.push(content)
                    }
                })
                tempArray.map((item) => {
                    let start_time = item.content_data.start_time
                    let startTimes = start_time.split(':')
                    let hours = this.formatTime(startTimes[0]) * 60 * 60
                    let mins = this.formatTime(startTimes[1]) * 60
                    let seconds = 0
                    let startSeconds = hours + mins + seconds
                    var totalSeconds = startSeconds + item.content_data.duration * 60
                    var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
                    totalSeconds %= (60 * 60); // 取余得到不足1小时的秒数
                    var minute = Math.floor(totalSeconds / 60); // 计算分钟
                    totalSeconds %= 60; // 取余得到不足1分钟的秒数
                    let show_end_time = this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute)
                    item.show_end_time = show_end_time
                    item.startSeconds = startSeconds
                    item.show_start_time = startTimes[0] + ':' + startTimes[1]
                    if (item.content_type == 'session_group') {
                        let format_data = this.formatSessionGroup(item.content_children)
                        item.content_children = format_data
                    }
                    return item
                })
                this.dataList = tempArray.sort((a, b) => {
                    return a.date - b.date
                })
            })
        },
        formatSessionGroup(chidren) {
            chidren.map((item) => {
                let start_time = item.start_time
                let startTimes = start_time.split(':')
                let hours = this.formatTime(startTimes[0]) * 60 * 60
                let mins = this.formatTime(startTimes[1]) * 60
                let seconds = 0
                let startSeconds = hours + mins + seconds
                var totalSeconds = startSeconds + item.duration * 60
                var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
                totalSeconds %= (60 * 60); // 取余得到不足1小时的秒数
                var minute = Math.floor(totalSeconds / 60); // 计算分钟
                totalSeconds %= 60; // 取余得到不足1分钟的秒数
                let show_end_time = this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute)
                item.show_end_time = show_end_time
                item.startSeconds = startSeconds
                item.show_start_time = startTimes[0] + ':' + startTimes[1]
                return item
            })
            chidren.sort((a, b) => {
                if (a.startSeconds > b.startSeconds) {
                    return 1
                }
                return -1
            })
            let array = chidren.sort((a, b) => {
                return a.date - b.date
            })
            return array
        },
        requestSessionGroups() {
            getSessionGroups(this.meeting_id).then((res) => {
                this.sessionGroups = res.data.data
            })
        },
        doRemoveSession(row) {
            this.$alert('你确定要删除该日程吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeSession(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },
        formatShowDate(time) {

            var weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
            var date = new Date(time * 1000)
            var dayOfWeek = date.getDay();


            return formatDate(new Date(time * 1000), 'yyyy-MM-dd') + ' (' + weekdays[dayOfWeek] + ')'
        },
        editSession(row) {
            this.custom_type = ''
            if (row) {
                console.log(222, row.start_time.split(':')[0])
                const date = new Date()
                date.setHours(Number(row.start_time.split(':')[0]))
                date.setMinutes(Number(row.start_time.split(':')[1]))
                this.sessionInfo = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    name: row.name,
                    date: dayjs(row.date * 1000).format('YYYY-MM-DD'),
                    start_time_date: date,
                    start_time: row.start_time,
                    duration: row.duration,
                    intro: row.intro,
                    type: row.type,
                    session_group_id: row.session_group_id,
                    partner_id: row.partner_id,
                    meeting_room_id: row.meeting_room_id,
                    check_ticket: row.check_ticket,
                    speechTime: row.speechTime
                }
            } else {
                this.sessionInfo = {
                    id: '',
                    meeting_id: this.meeting_id,
                    name: '',
                    date: this.dateRange[0],
                    start_time_date: new Date(),
                    start_time: '',
                    duration: '',
                    intro: '',
                    type: '',
                    session_group_id: '',
                    partner_id: '',
                    check_ticket: 'NO',
                    meeting_room_id: '',
                    speechTime: ''
                }
            }
            this.showSessionDialog = true
        },
        saveSession() {
            this.$refs.sessionForm.validate((val) => {
                if (val) {
                    if (!this.sessionInfo.type) {
                        this.sessionInfo.type = this.custom_type
                    }
                    const formData = {
                        ...this.sessionInfo,
                        date: dayjs(this.sessionInfo.date).valueOf() / 1000
                    }
                    formData.start_time = this.$dayjs(this.sessionInfo.start_time_date).format('HH:mm')
                    delete formData.start_time_date

                    updateSession(formData).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.showSessionDialog = false
                    })
                }
            })
        },
        toPPT(data) {
            this.$router.push({
                path: '/meeting/session/pptList',
                query: {
                    meeting_id: this.meeting_id,
                    session_id: data._id.$id
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
.table-name {
    flex: 3;
    padding: 0px 5px;
    box-sizing: border-box;
    line-height: 16px;
}

.table-type {
    flex: 2;
    font-weight: bold;
}

.table-date {
    flex: 3;
}

.table-start_time {
    flex: 2;
}

.table-total_times {
    flex: 1.5;
}

.table-end_date {
    flex: 2;
}

.table-zanzhu {
    flex: 3;
    font-weight: bold;
    padding: 0px 5px;
    box-sizing: border-box;
    line-height: 16px;
}

.table-room {
    flex: 3;
    font-weight: bold;
    padding: 0px 5px;
    box-sizing: border-box;
    line-height: 16px;
}

.table-operation {
    flex: 3.5;
    text-align: center;
}




.ql-container {
    height: 160px !important;
}
</style>